if ($(window).width() >= 1200) {
  const header = $('.header')

  $('.js-fullpage').fullpage({
    sectionSelector: '.js-fullpageSection',
    scrollingSpeed: 1000,
    navigation: true,
    navigationPosition: 'left',

    onLeave(_, nextIndex) {
      const el = $('.js-fullpageSection').eq(nextIndex - 1).find('[data-bg-color]')

      const bgColor = $(el).data('bg-color')

      if (bgColor) {
        header
          .css('background-color', bgColor)
          .attr('data-bg-header-color', bgColor)
      } else {
        header.removeAttr('style').removeAttr('data-bg-header-color')
      }
    },

    afterLoad() {
      const activeSlide = $('.fp-table.active')

      $('.aos-init', activeSlide).addClass('aos-animate')
    },
  })
}
