let screenCounter = 0

const text = ['SERVICE', 'Idea', 'Message', 'Story']

const sectionClass = [
  'hero-section_service',
  'hero-section_idea',
  'hero-section_message',
  'hero-section_story',
]

const colors = ['#f7a416', '#001f34', '#011F4A', '#334050']

$(window).on('load', () => {
  $('.hero-section').addClass('hero-section_service')
  $('.hero-background').eq(0).addClass('is-animate')

  setTimeout(() => {
    $('body').addClass('is-page-loaded')

    setInterval(() => changeScreen(), 3000)
  }, 500)
})

function changeScreen() {
  // eslint-disable-next-line no-plusplus
  screenCounter++

  if (screenCounter === 4) {
    screenCounter = 0
    $('.hero-backgrounds').css('background', colors[0])
  } else {
    $('.hero-backgrounds').css('background', colors[screenCounter])
  }

  $('.hero-section')
    .removeClass(sectionClass.join(' '))
    .addClass(sectionClass[screenCounter])

  $('.hero-title-sign').removeClass('is-animate')
  setTimeout(() => {
    $('.hero-title-sign').text(text[screenCounter]).addClass('is-animate')
  }, 100)

  $('.hero-background')
    .eq(screenCounter)
    .addClass('is-animate')
    .siblings()
    .removeClass('is-animate')

  $('.hero-image')
    .eq(screenCounter)
    .addClass('is-active')
    .siblings()
    .removeClass('is-active')
}
