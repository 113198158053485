import Swiper, { Pagination } from 'swiper'

Swiper.use([Pagination])

function scrollerInit() {
  $('.js-scroller').each((_, el) => {
    const container = $(el).find('.swiper-container')
    const image = $(el).find('.js-scrollerImage')
    const imageWidth = image.width() + 120
    const halfWidth = $(window).width() / 2
    const prevButton = $(el).find('.js-scrollerButtonPrev')
    const nextButton = $(el).find('.js-scrollerButtonNext')

    $(el)
      .find('.swiper-slide')
      .css('width', imageWidth + halfWidth)

    const swiperApi = new Swiper(container[0], {
      speed: 1000,
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
      freeMode: true,
      on: {
        slideChange() {
          detectPosition()
        },
        transitionStart() {
          detectPosition()
        },
      },
    })

    let counter = 0

    nextButton.on('click', () => {
      if (!swiperApi.isEnd) {
        counter += halfWidth
      }
      swiperApi.translateTo(-counter, 600)
      detectPosition()
    })

    prevButton.on('click', () => {
      if (!swiperApi.isBeginning) {
        counter -= halfWidth
      }
      swiperApi.translateTo(-counter, 600)
      detectPosition()
    })

    function detectPosition() {
      prevButton[swiperApi.isBeginning ? 'hide' : 'show']()
      nextButton[swiperApi.isEnd ? 'hide' : 'show']()
    }

    detectPosition()
  })
}

function scrollerMobile() {
  $('.js-scrollerImage').on('scroll', (e) => {
    const scroller = $(e.currentTarget).scrollLeft()
    const scrollWidth = $(e.currentTarget)[0].scrollWidth - $(window).width()
    const scrollBarLine = $(e.currentTarget)
      .parent()
      .find('.js-scrollerBarLine')
    const percent = (scroller * 100) / scrollWidth

    scrollBarLine.css('left', `${percent}%`)
  })
}

$(window).on('load', () => {
  if ($(window).width() >= 768) {
    scrollerInit()
  } else {
    scrollerMobile()
  }
})
