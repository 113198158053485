$(window).on('scroll load', () => {
  const scrolled = $(window).scrollTop()
  const header = $('.header')
  const headerHeight = header.height()

  $('.main > div').each((_, el) => {
    const offsetTop = $(el).offset().top - headerHeight
    const sectionHeight = $(el).height()

    if (scrolled > offsetTop && scrolled <= offsetTop + sectionHeight) {
      const bgColor = $(el).find('[data-bg-color]').data('bg-color')

      if (bgColor) {
        header.css('background-color', bgColor).attr('data-bg-header-color', bgColor)
      } else {
        header.removeAttr('style').removeAttr('data-bg-header-color')
      }
    }
  })
})
